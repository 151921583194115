module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1040,"linkImagesToOriginal":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Automaalaamo Auto & Muovi Fix Bilmåleri","short_name":"Automaalaamo Auto & Muovi Fix Bilmåleri","description":"Automaalaamo Auto & Muovi Fix - Muovikorjaus, puskurikorjaus, muovimaalaus, automaalaus","start_url":"/","background_color":"#16202c","theme_color":"#16202c","display":"fullscreen","icon":"src/images/auto-icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
