import React from 'react';
import logo from '../../images/auto-icon.png';
import logo2 from '../../images/auto-icon-98x148.png';
import logo3 from '../../images/auto-icon-200x200.png';

if (logo2) {};
if (logo3) {};
const Logo = () => {
  return <img src={logo} alt="Auto & Muovifix Logo" />;
};

export default Logo;
