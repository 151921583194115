// Only one item MUST have the "default: true" key

module.exports = {
  en: {
    default: false,
    path: `en`,
    locale: `en-US`,
    dateFormat: `DD/MM/YYYY`,
    siteLanguage: `en`,
    ogLanguage: `en_US`,
    defaultTitle: `Car Paint Shop Auto & Muovi Fix`,
    defaultDescription: `Plastic repair, bumper repair, plastic painting and auto painting`,
  },
  fi: {
    default: true,
    path: `fi`,
    locale: `fi-FI`,
    dateFormat: `DD/MM/YYYY`,
    siteLanguage: `fi`,
    ogLanguage: `fi_FI`,
    defaultTitle: `Automaalaamo Auto & Muovi Fix`,
    defaultDescription: `Muovikorjaus, puskurikorjaus, muovimaalaus, automaalaus`,
  },
  sv: {
    default: false,
    path: `sv`,
    locale: `sv-SE`,
    dateFormat: `DD/MM/YYYY`,
    siteLanguage: `sv`,
    ogLanguage: `sv_SE`,
    defaultTitle: `Bilmåleri Auto & Muovi Fix`,
    defaultDescription: `Plastreparation,stötfångare reparation, plast målning, bil målning`,
  },
}
