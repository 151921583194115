import React from 'react';
import useMenu from '../useMenu';
import styled from 'styled-components';
import { Link } from 'gatsby';
import LocalizedLink from '../LocalizedLink';


const Button2 = styled.button`
  color: black;
  font-size: 1.2em;
  margin: 1em;
  padding: 0.25em 1em;
  border: 2px solid black;
  border-radius: 3px;
  a { text-decoration: none; };
  background-color:cadetblue;
`;

const Mybuttons = props => {
  const menuItems = useMenu();

  return (
    
        menuItems.map(menu => (
       <Button2>  <LocalizedLink 
            to={menu.link}
            aria-label={menu.name}
            activeClassName="active"
            title={menu.name}
          >
            {menu.name}
          </LocalizedLink>
          </Button2>
        ))
   
        )
}
export default Mybuttons;
