import React from 'react';

import { Twitter } from 'styled-icons/boxicons-logos/Twitter';
import {  Instagram } from 'styled-icons/boxicons-logos/Instagram';
import * as S from './styled';

const SocialLinks = () => {
  return (
    <S.SocialLinksList>
      <S.SocialLinksItem>
        <S.SocialLinksLink
          href="https://www.instagram.com/automuovifix"
          title="Auto & Muovi Fix on Instagram"
          target="_blank"
          rel="noopener"
        >
          <Instagram />
        </S.SocialLinksLink>
      </S.SocialLinksItem>
      <S.SocialLinksItem>
        <S.SocialLinksLink
          href="https://twitter.com/kaj_makinen"
          title="Kaj Mäkinen on Twitter"
          target="_blank"
          rel="noopener"
        >
          <Twitter />
        </S.SocialLinksLink>
      </S.SocialLinksItem>
    </S.SocialLinksList>
  );
};

export default SocialLinks;
